/* eslint-disable no-param-reassign */
import { useEffect, useState } from "react";

import * as React from "react";
import { observer } from "mobx-react-lite";
import Grid from "@components/fondation/Grid/Grid";
import Stack from "@components/fondation/Stack/Stack";
import { useInstance } from "react-ioc";

import Box from "@components/fondation/Box/Box";
import Typography from "@components/fondation/Typography/Typography";
import SessionStore from "@store/auth/session.store";
import { useLocation, useNavigate } from "react-router-dom";
import UtilityCard from "@components/prototypes/utilities/UtilityCard";
import ListItem from "@components/elements/List/ListItem";
import List from "@components/elements/List/List";
import Loader from "@components/elements/Loader/Loader";
import { UserUtility } from "@model/types/user";
import ModalHeader from "@components/elements/HeaderBar/ModalHeader";
import ModalBox from "@components/elements/Modal/ModalBox";
import NavLink from "@components/elements/NavLink/NavLink";
import { trackEvent } from "@model/utils/tracking";
import UseRudderStack from "@components/prototypes/hooks/useRudderStack";

const TITLE = "Select your electric utility";
const MESSAGE =
    "Select your utility so we can check if you're overpaying for energy.";

const SelectUtilityPage: React.FC = observer(() => {
    const location = useLocation();
    const navigate = useNavigate();
    const sessionStore = useInstance(SessionStore);
    const [showMore, setShowMore] = useState<boolean>(false);
    const handleShowMore = () => {
        UseRudderStack.INSTANCE.trackEvent(
            "select_utility-link_click-show_more_utilities",
        );
        setShowMore(!showMore);
    };
    const handleBack = () => {
        navigate("/area");
    };

    const handleSelect = (utility: UserUtility) => async () => {
        const error = await sessionStore.selectUtility(utility);
        if (!error) {
            navigate(`/enrollment/get-started?utility-id=${utility.id}`);
        }
        trackEvent("Incomplete");
        UseRudderStack.INSTANCE.trackEvent(
            "select_utility-button_click-utility_select",
        );
    };

    const results = sessionStore.utilities?.electric ?? [];
    const hasDefault = results.findIndex((res) => res.default) !== -1;

    if (results.length === 1 && !showMore) {
        setShowMore(true);
    }

    if (results.length >= 1) {
        results.forEach((utility) => {
            if (
                utility.name.indexOf("(") > 1 &&
                utility.name.startsWith("Eversource")
            ) {
                utility.name = utility.name.substring(
                    0,
                    utility.name.indexOf("("),
                );
            }
            if (utility.name.startsWith("Eversource") && !utility.default) {
                utility.disabled = true;
            }
        });
    }

    const handleFilter = (res: UserUtility, index: number) => {
        if (results.length > 1) {
            if (showMore) {
                return !res.disabled; // show all
            }
            if (hasDefault) {
                return res.default; // show default
            }
            return index === 0; // no default
        }
        return true; // only one utility
    };

    const zipCode = sessionStore.area?.postalCode ?? null;
    const loading = sessionStore.isLoading;

    useEffect(() => {
        if (sessionStore.isAreaValid && !sessionStore.isLoading) {
            UseRudderStack.INSTANCE.pageEvent("sign-up", "select_utility", {
                url: window.location.href,
                title: TITLE,
            });
        }
    }, []);

    useEffect(() => {
        if (!sessionStore.isLoading && !sessionStore.isAreaValid) {
            navigate(`/area${location.search}`, { replace: true });
        }
    }, [sessionStore.isLoading]);

    return (
        <ModalBox flexBasis="100%" overflow="hidden">
            <ModalHeader
                BackButtonProps={{
                    onClick: handleBack,
                }}
                hideEndButton
            />

            <Grid container flexDirection="column" alignItems="stretch">
                <Grid item m={4} mb={8}>
                    <Typography variant="h3" mb={2.5}>
                        {TITLE}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={(t) => ({ color: t.palette.grey[800] })}
                    >
                        {MESSAGE}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="subtitle2"
                        color="grey.700"
                        fontWeight={700}
                        marginX={4}
                    >
                        {showMore && results.length > 1
                            ? `${results.length} Results for ${zipCode}`
                            : `Top Result for ${zipCode}`}
                    </Typography>
                </Grid>
            </Grid>

            {loading ? (
                <Loader size="large" center />
            ) : (
                <Stack
                    direction="column"
                    alignItems="stretch"
                    flex={1}
                    minHeight={0}
                    minWidth={0}
                >
                    <Box
                        flex={1}
                        minHeight={0}
                        minWidth={0}
                        sx={{ overflow: "hidden", overflowY: "scroll" }}
                    >
                        <List>
                            <>
                                {results.filter(handleFilter).map((utility) => (
                                    <ListItem key={utility.name}>
                                        <UtilityCard
                                            data={utility}
                                            onClick={handleSelect(utility)}
                                        />
                                    </ListItem>
                                )) ?? "Not found"}
                            </>
                        </List>
                    </Box>
                    <Box textAlign="center" mt={4} mb={8} height={35}>
                        <Typography variant="body2">
                            {results.length > 1 && (
                                <Typography
                                    align="center"
                                    variant="h6"
                                    onClick={handleShowMore}
                                    sx={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        mt: "4px",
                                    }}
                                >
                                    {!showMore && "Show More Utilities"}
                                </Typography>
                            )}
                            {showMore && (
                                <NavLink
                                    onClick={() => {
                                        UseRudderStack.INSTANCE.trackEvent(
                                            "select_utility-link_click-my_utility_is_not_listed",
                                        );
                                    }}
                                    to="/area/unsupported"
                                >
                                    My utility is not listed
                                </NavLink>
                            )}
                        </Typography>
                    </Box>
                </Stack>
            )}
        </ModalBox>
    );
});

export default SelectUtilityPage;
